import React from "react";
import "./styles.scss";

import Advogado1 from "../../assets/images/advogado1.png";
import Advogado2 from "../../assets/images/advogado2.png";

const Team = () => {
  return (
    <section className="team" id="advogados-socios">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="title-wrapper">
              <h1 className="title">Advogados</h1>
              <div className="small-border"></div>
              <p className="subtitle">
                Descubra o que nós podemos fazer para tirar do seu caminho{" "}
                <br /> as preocupações com situações que fogem ao seu controle!
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 advogado-um">
            <img src={Advogado1} alt="Advogado Jaime R. de Almeida Neto" />
            <p className="advogado-nome">Jaime R. de Almeida Neto</p>
          </div>
          <div className="col-12 col-sm-12 col-md-6 advogado-dois">
            <img src={Advogado2} alt="Advogado Jaime R. de Almeida Neto" />
            <p className="advogado-nome">Gustavo Henrique C. Campanati</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
