import React from "react";
import "./styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const TextSection = () => {
  return (
    <section className="text-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 padding-col">
            <div className="title-text">
              O que são as coisas mais <br />
              importantes na sua vida?
            </div>
            <p>Dificilmente, você responderá algo que não seja:</p>
            <ul className="list">
              <li>
                <FontAwesomeIcon icon={faCheck} className="icon" />
                Sua honra
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} className="icon" />
                Seu patrimônio
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} className="icon" />
                Sua liberdade
              </li>
            </ul>
            <p>
              E são exatamente essas coisas que você corre o risco de perder,{" "}
              <br />
              quando se depara com um processo judicial, de qualquer que seja a
              natureza.
            </p>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-6 padding-col">
            <div className="title-text">
              Nos colocamos como defensores <br />
              dos seus valores fundamentais, por <br /> meio de algumas regras
              que para <br /> nós, são inegociáveis.
            </div>
            <p>
              Tratamos cada processo como se fosse único, pois em muitos casos é
              o único processo que você tem na vida!
            </p>
            <ul className="list">
              <li>
                <FontAwesomeIcon icon={faCheck} className="icon" />
                Isso significa que traçamos uma estratégia específica, diferente
                e única para solucionar cada caso.
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} className="icon" />
                Encaramos a advocacia como uma atividade artesanal, o que exige
                cuidado na condução do processo e atenção às necessidades de
                cada cliente!
              </li>
              <li>
                <FontAwesomeIcon icon={faCheck} className="icon" />
                Ao longo de mais de 20 anos de atuação, criamos um repertório
                que nos possibilita trafegar pelos ramos do direito que
                normalmente afetam a vida dos nossos clientes.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextSection;
