import React from "react";
import { useParams } from "react-router-dom";

import PostItem from "./components/PostItem";
import Header from "../Header";
import Footer from "../Footer";
import DivorcioImagem from "../../assets/midia_mock2.jpg";
import PatrimonioFamiliaImagem from "../../assets/midia_mock3.jpg";
import CodigoCivilImagem from "../../assets/midia_mock4.jpg";

const Posts = () => {
  let { id } = useParams();

  const getPost = (id) => {
    const Posts = {
      1: {
        titulo: "A Crônica de um divórcio – O fim de uma sociedade",
        descricao: (
          <p>
            Minha formação acadêmica e profissional sempre derivou para o
            Direito Empresarial. Não sei bem ao certo o que me levou por tais
            caminhos: se foram minhas experiências pessoais, desde bem jovem, ou
            se foram os excelentes professores que tive nesta área. O motivo não
            importa. Importa apenas dizer: o tema sempre me despertou interesse
            e curiosidade, com especial gosto pelo Direito Societário. <br />
            Com o passar dos anos, vi-me também, muitas vezes, envolvido em
            questões de Direito de Família, percebendo em mim o mesmo fascínio
            por essa área. Mas, por que lhe conto minhas “intimidades”, caro
            leitor, neste espaço dos Advogados, caso esteja se perguntando?
            Escrevo para compartilhar uma óbvia conclusão que me assaltou dia
            desses. É tão simples que agora soa pueril. Mas, por (muitas) vezes,
            e realmente é clichê dizer isso, não percebemos o que está bem à
            nossa frente.
            <br /> A pergunta: O que o Direito Societário e o Direito de Família
            têm em comum, a ponto de me despertar a mesma sensação? A resposta:
            Óbvio, ambos tratam das relações interpessoais! Tratam do nascimento
            e, por vezes, do fim de uma sociedade. Uma empresarial, outra
            conjugal. Mas, ainda assim, o que isso tem de relevante para estar
            sendo escrito (e lido) nesta coluna? Explico: É que, a partir desta
            singela premissa, posso compartilhar algumas conclusões a que
            cheguei nesta década e pouco de “causos”. <br />
            Pois bem, ninguém (normal) se associa empresarialmente a alguém para
            dissolver a sociedade; da mesma maneira, duas pessoas não se unem,
            premeditadamente, para se separarem! Os sonhos iniciais são
            absolutamente os mesmos, guardadas as diferenças de cada espécie: –
            “Nossa família vai ser feliz e prosperar”, pensa o casal. – “Nosso
            negócio vai ser bom e prosperar”, pensam os empresários. E não
            poderia ser diferente, pois o ser humano intrinsecamente busca ser
            feliz e realizado – mesmo que, em ambos os casos, alguns pendam,
            involuntariamente, para a (auto) destrutividade. <br />O Direito
            Societário há muito criou formas e modelos de associação empresarial
            entre pessoas, prescrevendo uma maneira objetiva de solução de
            conflitos, ainda que nem sempre as soluções se mostrem eficazes ou
            práticas, como poderemos debater noutra oportunidade. A título de
            exemplo, já em 1919, a legislação comercial da sociedade limitada
            permitia a saída da sociedade, do sócio que divergisse da alteração
            do contrato social (art. 15 do Decreto 3.708).
            <br /> Por sua vez, o Direito de Família, compreensivelmente mais
            arraigado às questões socioculturais e religiosas, andou a passos
            bem mais lentos. Basta citar que o divórcio foi admitido no Brasil
            apenas em 1977, uma vez que, desde a Constituição de 1934, tínhamos
            que “a família, constituída pelo casamento indissolúvel, se encontra
            sob a proteção direta do Estado” (art. 144). Apenas com a Emenda da
            Constituição nº 66, de 2010(!), passou-se a permitir a dissolução do
            casamento pelo divórcio, sem a antecedência de prazos ou condições
            prévias (separação judicial). <br />
            Mas, se demorou tanto para chegarmos a este estágio no Direito de
            Família, vale refletir sobre o porquê da demora e da mudança. A meu
            ver, se a demora se explica facilmente pelo já mencionado acima
            (fatores sociais, culturais e religiosos), não é tão simples falar
            do motivo da mudança de entendimento do legislador. De um lado,
            temos a tendência de seguir na direção da menor intervenção do
            Estado na vida privada, e de outro, temos que ponderar sobre o que
            já designado por psicólogas estudiosas do tema, de
            Divórcio-Destrutivo.
            <br /> Nas relações empresariais, falando de forma genérica, a falta
            de boa relação entre os sócios (quebra da affectio societatis) é, na
            maioria das vezes, motivo suficiente para a resolução da sociedade
            para o sócio que se retira. É bem verdade que a solução das questões
            patrimoniais pode se arrastar por anos, mas o “divórcio” da
            sociedade se dá. Nas relações familiares, a vontade do cônjuge que
            busca o fim do casamento também deve prevalecer e subsistir em si
            mesma. As questões correlatas, como, e.g. guarda e visita de filhos
            menores, eventuais pensões, partilha de bens, etc., precisarão ser
            resolvidas, mas o vínculo jurídico deve ser extinto desde logo,
            “apenas” porque este é o desejo de alguém. Busca-se assim evitar ou,
            ao menos, mitigar o Divórcio-Destrutivo, em que o cônjuge necessita
            ganhar o processo a qualquer custo, simplesmente pela tentativa de
            desvalorizar/desqualificar o outro. São os casos em que os cônjuges
            medem forças no contexto judicial, muitas vezes distorcendo a
            realidade, os fatos e as provas, em busca da “vitória”.
            <br /> Quando há filhos menores no Divórcio-Destrutivo, muitas vezes
            o cônjuge usa os filhos como arma para atingir o outro genitor. Pode
            haver (e geralmente há) a dificuldade do cônjuge em diferenciar e
            separar o papel de pai/mãe do papel conjugal e o rancor conjugal,
            criado com o fim do matrimônio, acaba superando o interesse no
            bem–estar dos filhos. Aos advogados cabe, uma vez verificada a
            intenção firme do fim da sociedade, buscar a solução jurídica menos
            traumática para todos os envolvidos, em especial para os menores,
            utilizando o processo como meio de solução de conflitos e não como
            gerador deles. <br />
            Pois é… desde o “unidos até que a morte os separe” até o “que seja
            eterno enquanto dure” do poeta, muitas histórias e estórias se
            passam e continuarão a passar… <br />
            Gustavo Henrique Coimbra Campanati é advogado e professor
            universitário, pós-graduado pela PUC/SP
          </p>
        ),
        imagem: DivorcioImagem,
      },
      2: {
        titulo: "Holding patrimonial A proteção do patrimônio familiar",
        descricao: (
          <p>
            Em tempos em que a carga tributária é enorme e a insegurança
            jurídica prevalece nas relações negociais, uma das preocupações do
            empresariado em geral é em relação à proteção do patrimônio
            familiar, e a melhor maneira de proteger a futura herança deixada,
            bem como evitar ações de terceiros, poupando com isso tempo e outros
            aborrecimentos. <br />
            Diante desse panorama, apresentaremos algumas alternativas lícitas
            de soluções para estes problemas, utilizadas por empresários que
            pretendem proteger seu patrimônio pessoal, poupando maiores
            transtornos no momento da transmissão da herança, além de
            significar, inclusive, considerável economia tributária. <br />
            Uma dessas alternativas utilizadas por empresários é a doação em
            vida de todo seu patrimônio, realizando assim, a antecipação da
            herança, distribuindo seus bens entre os sucessores de modo
            igualitário. Com esta prática, no momento da transmissão da herança,
            não mais haverá bens a serem inventariados, nem imposto de
            transmissão causa mortis a ser recolhido, desonerando o patrimônio
            do empresário. <br />
            Outra alternativa escolhida por alguns empresários é a elaboração de
            testamento. Através deste documento, o empresário pode determinar,
            dentro das limitações legais, a divisão de seus bens entre os
            herdeiros. Pelo novo código civil, são herdeiros necessários os
            descendentes, ascendentes e o cônjuge. Assim, o responsável pelo
            patrimônio pode distribuir apenas 50% de seus bens para quem ele
            quiser. Os outros 50% pertencem por determinação legal aos herdeiros
            necessários, que os receberão em partes proporcionais mesmo que isso
            não esteja previsto em testamento. Por conta disso, essa alternativa
            jurídica protetiva é limitada, sendo que o patriarca, através de
            testamento, só poderá dispor integralmente de seu patrimônio quando
            não possuir nenhum herdeiro necessário. <br />
            Outra alternativa a ser considerada pelos empresários é a criação de
            empresas de administração patrimonial, as conhecidas HOLDING´S
            PATRIMONAIS. Uma empresa holding patrimonial tem a finalidade de
            controlar o patrimônio de uma ou mais pessoas físicas, ou seja, ao
            invés das pessoas físicas possuírem bens em seus próprios nomes,
            possuem através de uma pessoa jurídica – a controladora patrimonial,
            que geralmente se constitui na forma de uma sociedade limitada que,
            via de regra, tem a seguinte denominação social: (nome patronímico,
            ou outro à escolha) “Empreendimentos, ou Participações, Comercial
            Ltda.” <br />
            Dentre as principais vantagens desta operação, estão a redução da
            carga tributária incidente sobre os rendimentos da pessoa física
            (IRPF), a possibilidade de realização de planejamento sucessório
            (herança), a preservação do patrimônio pessoal (que pertence à
            holding) perante credores de uma outra pessoa jurídica (empresa) da
            qual a pessoa física participe como sócio ou acionista, etc. <br />
            Enfim, a opção pela constituição de uma pessoa jurídica que controle
            o patrimônio da pessoa física – Holding Patrimonial – implica em
            vantagens verdadeiramente concretas, posto que os bens da pessoa
            física, que será apenas titular de quotas sociais, passam para a
            pessoa jurídica. <br />
            Assim, pela constituição de uma Holding Patrimonial, é possível
            estabelecer-se um planejamento sucessório. <br />
            Conforme o novo Código Civil, o empresário ou empresária casado(a)
            que constituir pessoa jurídica pode, sem necessidade de outorga
            conjugal, qualquer que seja o regime de bens, alienar os imóveis que
            integrem o patrimônio da empresa ou gravá-los de ônus real. Assim
            sendo, incorporando-se os bens da pessoa física à pessoa jurídica,
            estes poderão ser distribuídos livremente, antes mesmo que o sócio
            venha a falecer. Evita-se, desta maneira, a ansiedade por parte da
            linha sucessória, posto que o quinhão de cada participante fica
            definido antes mesmo do falecimento do sócio patriarca. <br />
            Ademais, a sucessão fica facilitada por meio da transmissão das
            quotas sociais da empresa, eis que a distribuição dos bens é feita
            mediante a respectiva distribuição das quotas sociais da empresa.
            <br />
            Desta maneira, evitam-se os custos demasiados e desgastes emocionais
            de um inventário, já que, estando organizada a sucessão dos bens por
            meio da distribuição das quotas sociais, o procedimento judicial do
            inventário será mais célere e menos oneroso. <br />
            Dessa forma, com esta breve explanação sobre este tema tão
            importante para o empresariado, procuramos demonstrar algumas das
            possibilidades lícitas que o ordenamento jurídico brasileiro oferece
            para quem pretende proteger seu patrimônio pessoal. Todavia,
            recomendamos também que cada caso seja estudado criteriosamente, e
            que somente após um estudo de todo o contexto, seja escolhida a
            melhor solução protetiva patrimonial.
          </p>
        ),
        imagem: PatrimonioFamiliaImagem,
      },
      3: {
        titulo: "As empresas e o novo código civil",
        descricao: (
          <p>
            Muito se falou nestes últimos dois anos sobre a adequação dos
            contratos sociais das empresas ao novo Código Civil. O prazo para
            esta adequação, após duas prorrogações, terminou no último dia 11 de
            janeiro. Mas, quais as implicações que a falta de adequação, ou
            ainda, que as adequações mal feitas dos contratos sociais ao novo
            Código Civil podem ocasionar? <br />
            Inicialmente, cumpre-nos dizer que não é nossa pretensão, neste
            breve artigo, debater todos os aspectos inerentes ao tema. O que se
            pretende, é alertar o leitor quanto aos riscos que passa a correr,
            quer como empresário, quer como consumidor de produtos e serviços,
            pela falta de adequação da empresa à nova lei. <br />
            Para tal, dividiremos o tema em dois momentos: a) sociedades
            empresárias que não se adequaram ao novo Código Civil; b) sociedades
            empresárias que fizeram mal esta adequação. <br />
            No primeiro caso – da sociedade empresária que não se adequou ao
            novo Código Civil – vale lembrar que a legislação não trouxe nenhuma
            sanção objetiva para estes casos. Não existe multa para o empresário
            que deixou de adequar sua empresa. Todavia, espera-se que esta
            sanção seja dada pelo próprio mercado. Ou seja, a coletividade
            regulará a efetiva aplicação da lei. O empresário que deixou de se
            adequar ao novo comando, passará a sofrer restrições de ordem
            prática, que certamente afetarão o dia-a-dia da atividade
            empresarial. <br />
            Apenas como exemplo, podemos citar algumas dificuldades que já estão
            sendo observadas no cotidiano empresarial. As empresas que deixaram
            de adaptar seu contrato social à nova legislação, já encontram
            dificuldades ao pleitear financiamentos públicos, bem como obter
            empréstimos bancários. Já encontram objeções ao ter que renovar
            contratos de locação e de leasing. Também não poderão participar de
            licitações e concorrências públicas e, certamente terão seus
            créditos revistos por seus parceiros comerciais, posto que estes não
            têm intenção de contratar ou oferecer crédito para empresas
            irregulares. E ainda, certamente observarão a recusa dos órgãos de
            registro (Juntas Comerciais e Registros Civis) em promover novos
            arquivamentos de alterações contratuais, enquanto não forem feitas
            as adaptações necessárias. <br />
            Já num segundo momento, observamos os casos das empresas que se
            enquadram na segunda hipótese – a das sociedades empresárias que
            fizeram mal a adequação ao novo Código. O que se observou em muitos
            casos foram empresários que, com o intuito de “regularizar sua
            empresa” à nova legislação, socorreram-se de modelos ou até mesmo
            impressos para efetivá-la. Estes erram duplamente. Inicialmente
            perderam uma grande oportunidade para corrigir falhas em seu
            contrato social, deixando de fazer uma análise criteriosa das
            características da sociedade, do histórico da empresa e dos
            interesses dos sócios, perdendo assim a chance de definir e
            determinar inúmeras outras questões societárias, uma vez que o novo
            Código oferece uma ampla gama de opções a serem definidas pelo
            contrato social. <br />
            E, em conseqüência disto, no futuro (que pode ser próximo),
            certamente enfrentarão questões de difícil solução, tais como a
            saída imotivada de um sócio, a morte e sucessão de sócio, a expulsão
            de um sócio, a cessão e alienação de cotas sociais ou até mesmo
            quanto à responsabilidade dos sócios pelos atos praticados em nome
            da sociedade ou mesmo em nome próprio. Obrigando assim, a sociedade
            empresária ou seus sócios a socorrerem-se do Poder Judiciário para
            ver seus direitos garantidos ou ter suas dúvidas dirimidas, ao passo
            que poderiam, no contrato social, ter previsto a melhor forma de
            solucionar tais casos. <br />E ainda, vale esclarecer que as
            sociedades que não fizeram acertadamente a adaptação de seu contrato
            social correm um risco maior de ter sua personalidade jurídica
            desconsiderada numa demanda judicial, responsabilizando os sócios a
            responder com seu patrimônio pessoal por obrigações assumidas pela
            pessoa jurídica irregular. <br />
            Por fim, é bom lembrar que a regularização da empresa aos olhos do
            novo Código Civil, ou mesmo a correção de imperfeições nas
            alterações já depositadas, pode e deve ser feita a qualquer tempo,
            pois mais uma vez vale aqui o adágio popular que diz – É melhor
            prevenir do que remediar.
          </p>
        ),
        imagem: CodigoCivilImagem,
      },
    };

    return Posts[id] ? (
      <PostItem post={Posts[id]} />
    ) : (
      (window.location.href = "/")
    );
  };

  return (
    <>
      <Header />
      {getPost(id)}
      <Footer />
    </>
  );
};

export default Posts;
