import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import "./styles.scss";
import Logo from "../../assets/images/logo_branca_horizontal.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhone,
  faEnvelope,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";

import { sendEmail } from "../../utils/sendEmail";
import { formatData } from "../../utils/formatData";

const Footer = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleError = () => setError(true);

  const cleanForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };

  const submitForm = async (event) => {
    event.preventDefault();

    const templateEmailParams = {
      subject: formatData(),
      name,
      email,
      phone,
      message,
    };

    cleanForm();

    const { status } = await sendEmail(templateEmailParams);

    status === 200 ? handleShow() : handleError();
  };

  const FormModal = () => {
    if (error) return (
      <>
        <Modal show={error} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Ooops!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Infelizmente não conseguimos receber a solicitação, pode tentar enviar novamente mais tarde?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Enviado com sucesso!</Modal.Title>
          </Modal.Header>
          <Modal.Body>Grato por sua solicitação! Em breve entraremos em contato!</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <footer className="footer" id='footer' >
      <div className="container">
        <div className="row">
          <FormModal/>
          <div className="col-lg-4">
            <div className="widget">
              <img src={Logo} alt="" />
              <p className="abstract">
                Fundado em 2001, o Almeida Neto e Campanati – Advogados é, hoje,
                o resultado da união de profissionais de talento, com grande
                capacidade técnica e experiência em diferentes segmentos do
                direito
              </p>
              <address className="s1">
                <div className="contact-item">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                  Av. Dr. Eugênio Salerno, 496 <br />
                  Centro, Sorocaba - SP
                </div>
                <div className="contact-item">
                  <FontAwesomeIcon icon={faPhone} className="icon" />
                  (15) 3334-9494
                </div>
                <div className="contact-item">
                  <FontAwesomeIcon icon={faEnvelope} className="icon" />
                  <a href="mailto:contact@example.com">advogados@anc.adv.br</a>
                </div>
              </address>
            </div>
          </div>
          <div className="col-md-4">
            <h5 className="id-color mb20">Mapa do Site</h5>
            <ul className="ul-style-2">
              <a href="/">
                <li>
                  <FontAwesomeIcon icon={faCheck} className="icon" />
                  Home
                </li>
              </a>
              <a href="#quem-somos">
                <li>
                  <FontAwesomeIcon icon={faCheck} className="icon" />
                  Quem somos
                </li>
              </a>
              <a href="#areas-atuacao">
                <li>
                  <FontAwesomeIcon icon={faCheck} className="icon" />
                  Áreas de atuação
                </li>
              </a>
              <a href="#advogados-socios">
                <li>
                  <FontAwesomeIcon icon={faCheck} className="icon" />
                  Sócios
                </li>
              </a>
              <a href="#midia">
                <li>
                  <FontAwesomeIcon icon={faCheck} className="icon" />
                  Mídia
                </li>
              </a>
              <a href="#contato">
                <li>
                  <FontAwesomeIcon icon={faCheck} className="icon" />
                  Contato
                </li>
              </a>
            </ul>
          </div>
          <div className="col-lg-4" id="contato">
            <h5 className="id-color">Contato</h5>
            <form onSubmit={submitForm}>
              <div className="mb-2">
                <input
                  type="text"
                  name="name"
                  value={name}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Nome Completo"
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
              <div className="mb-2">
                <input
                  type="email"
                  name="email"
                  value={email}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="E-mail"
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
              <div className="mb-2">
                <input
                  type="tel"
                  name="phone"
                  value={phone}
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Telefone"
                  onChange={(event) => setPhone(event.target.value)}
                />
              </div>
              <div className="mb-3">
                <textarea
                  className="form-control"
                  name="message"
                  value={message}
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder="Escreva sua mensagem..."
                  onChange={(event) => setMessage(event.target.value)}
                ></textarea>
              </div>
              <button
                type="submit"
                className="btn btn-primary"
              >
                Enviar
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="de-flex">
                <div className="de-flex-col">
                  © Copyright {new Date().getFullYear()} - Almeida Neto e
                  Campanati Advogados - Desenvolvido por Going2 Mobile
                </div>
                <div className="de-flex-col">
                  <div className="social-icons">
                    <a
                      href="https://www.facebook.com/almeidaneto.campanatiadvogados/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="icon-midias">
                        <i className="fab fa-facebook-f"></i>
                      </div>
                    </a>
                    <a
                      href="https://www.instagram.com/anc_advogados/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="icon-midias">
                        <i className="fab fa-instagram"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
