import React from "react";
import "./styles.scss";

const PostItem = ({ post }) => {
  return (
    <section className="post">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div
              id="carouselExampleSlidesOnly"
              className="carousel slide"
              data-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={post.imagem}
                    className="d-block w-100"
                    alt="Imagem ilustrativa para a notícia"
                  />
                </div>
              </div>
            </div>
            <h2 className="card-title">{post.titulo}</h2>
            {post.descricao}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PostItem;
