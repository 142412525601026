import React from "react";

import Header from "./Header";
import CallAction from "./CallToAction";
import Banner from "./Banner";
import AboutUs from "./AboutUs";
import Gallery from "./Gallery";
import TextSection from "./TextSection";
import Team from "./Team";
import Areas from "./Areas";
import Midia from "./Midia";
import Footer from "./Footer";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  return (
    <>
      <Header />
      <Banner />
      <CallAction />
      <AboutUs />
      <Gallery />
      <TextSection />
      <Team />
      <Areas />
      <Midia />
      <Footer />
    </>
  );
};

export default App;
