import React from "react";
const MidiaItem = ({ day, month, imagem, titulo, descricao }) => {
  return (
    <div className="card">
      <div className="card-img-top">
        <div className="date-box">
          <div className="day">{day}</div>
          <div className="month">{month}</div>
        </div>
        <img src={imagem} alt="Imagem ilustrativa da notícia" />
      </div>
      <div className="card-body">
        <h2 className="card-title">{titulo}</h2>
        <p className="card-text">{descricao}</p>
      </div>
    </div>
  );
};

export default MidiaItem;
