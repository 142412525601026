import React from "react";

import "./styles.scss";
import Img1 from "../../assets/images/IMG_5735.jpg";
import Img2 from "../../assets/images/IMG_5729.jpg";
import Img3 from "../../assets/images/IMG_5762.jpg";

const Gallery = () => {
  return (
    <section className="gallery">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="card-img animate__animated animate__fadeInRight animate__delay-1s">
              <img src={Img1} alt="" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-img animate__animated animate__fadeInRight animate__delay-1s">
              <img src={Img2} alt="" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-img animate__animated animate__fadeInRight animate__delay-1s">
              <img src={Img3} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;
