import React from "react";
import "./styles.scss";

import CronicaImagem from "../../assets/midia_mock2.jpg";
import HoldingImagem from "../../assets/midia_mock3.jpg";
import CodigoImagem from "../../assets/midia_mock4.jpg";
import MidiaItem from "./components/MidiaItem";

const Midia = () => {
  return (
    <section className="midia" id="blog">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="title">Blog</h1>
            <div className="small-border"></div>
          </div>
          <div className="col-12 col-sm-12 col-md-4">
            <a href="/post/1">
              <MidiaItem
                day="26"
                month="OUT"
                imagem={CronicaImagem}
                titulo="A Crônica de um divórcio – O fim de uma sociedade"
                descricao=" Minha formação acadêmica e profissional sempre derivou para o
                  Direito Empresarial. Não sei bem ao certo o que me levou por
                  tais caminhos: se foram minhas experiências pessoais, desde
                  bem jovem, ou se foram os excelentes professores que tive
                  nesta área. O motivo não importa. Importa apenas dizer: o tema
                  sempre me despertou interesse e curiosidade, com especial
                  gosto pelo Direito Societário."
              />
            </a>
          </div>
          <div className="col-12 col-sm-12 col-md-4">
            <a href="/post/2">
              <MidiaItem
                day="03"
                month="OUT"
                imagem={HoldingImagem}
                titulo="Holding patrimonial A proteção do patrimônio familiar"
                descricao="Em tempos em que a carga tributária é enorme e a insegurança
              jurídica prevalece nas relações negociais, uma das
              preocupações do empresariado em geral é em relação à proteção
              do patrimônio familiar, e a melhor maneira de proteger a
              futura herança deixada, bem como evitar ações de terceiros,
              poupando com isso tempo e outros aborrecimentos."
              />
            </a>
          </div>
          <div className="col-12 col-sm-12 col-md-4">
            <a href="/post/3">
              <MidiaItem
                day="02"
                month="OUT"
                imagem={CodigoImagem}
                titulo=" As empresas e o novo código civil"
                descricao="Muito se falou nestes últimos dois anos sobre a adequação dos
              contratos sociais das empresas ao novo Código Civil. O prazo
              para esta adequação, após duas prorrogações, terminou no
              último dia 11 de janeiro. Mas, quais as implicações que a
              falta de adequação, ou ainda, que as adequações mal feitas dos
              contratos sociais ao novo Código Civil podem ocasionar?"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Midia;
