import React from "react";
import "./styles.scss";

const Banner = () => {
  return (
    <section className="banner">
      <div className="container-fluid">
        <h1 className="title animate__animated animate__fadeInUp">
          Sua tranquilidade depende de como <br />
          você age frente às adversidades.
        </h1>
        <p className="subtitle animate__animated animate__fadeInUp">
          Imagine poder focar na sua empresa, sem se preocupar com os <br />
          problemas jurídicos que tiram seu sono e drenam sua energia.
        </p>
        <a href="#contato">
          <button className="btn btn-primary animate__animated animate__fadeInUp">
            Contato
          </button>
        </a>
      </div>
    </section>
  );
};

export default Banner;
