import React from "react";
import Modal from "react-bootstrap/Modal"
import "./styles.scss";


const BasicModal = ({ isOpen, onClose, title, children, id, inContact }) => {
  return (
    <Modal show={isOpen} size="lg" onHide={() => {onClose(id)}}>
      <Modal.Header closeButton >
        <Modal.Title>{title}</Modal.Title> 
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => {onClose(id);}}>
          Fechar
        </button>
        {inContact && (
          <a className="btn btn-primary" href='#footer' onClick={() => {onClose(id);}}>
            Entre em Contato
          </a>  
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default BasicModal;