import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import App from "./components/App";
import Posts from "./components/Posts";

export default (props) => (
  <BrowserRouter>
    <Switch>
      <Route path="/" exact={true} component={App} />
      <Route path="/post/:id" exact={true} component={Posts} />
    </Switch>
  </BrowserRouter>
);
