import emailjs from 'emailjs-com';

export const sendEmail = (emailTemplateParams) => {
  const response = emailjs.send(
    "service_q8ngs3y",
    "template_ec2v04h",
    emailTemplateParams,
    "user_cVSNBb4NAVQOAxvkH6AWd",
  ).then((res) => {
    console.log("SUCCESS!", res.status, res.text);
    return res;
  })
  .catch((err) => {
    console.log("FAILED...", err)
  });

  return response;
}
