/* eslint-disable no-use-before-define */
import React, { useState } from "react";

import "./styles.scss";
import BasicModal from '../Modal';

import Logo from "../../assets/images/logo.png";

const Areas = () => {
  const [openModals, setOpenModals] = useState({
    risco: false,
    criminal: false,
    tributario: false,
    societario: false,
    trabalhista: false,
    familia: false,
    patrimonial: false,
    imaterial: false,
  });
  const [titleModal, setTitleModal] = useState('Hi');

  const openModalRisco = () => {
    setTitleModal('Questões jurídicas relativas à integridade corporativa. Equipe habilitada para identificar e conduzir procedimentos de compliance, due diligence em fusões e aquisições, joint ventures e outros.');

    handleOpenModal('risco');
  }
  const openModalCriminal = () => {
    setTitleModal('Assessoria ao empresário em todas as áreas relacionadas ao Direito Criminal, abrangendo uma ampla variedade de serviços visando a proteção da integridade da empresa e dos seus sócios.');

    handleOpenModal('criminal');
  }
  const openModalTributario = () => {
    setTitleModal('Assessoria e consultoria jurídica completa no âmbito tributário, para o empresário tomar decisões eficientes sem correr riscos junto ao Governo e à Receita.');

    handleOpenModal('tributario');
  }
  const openModalSocietario = () => {
    setTitleModal('Atuação ampla e eficiente em todos os momentos da relação societária, da sua constituição à dissolução.');

    handleOpenModal('societario');
  }
  const openModalTrabalhista = () => {
    setTitleModal('Atuação preventiva e consultiva para que muitas questões de ordem trabalhistas se resolvam antes do processo.');

    handleOpenModal('trabalhista');
  }
  const openModalFamilia = () => {
    setTitleModal('Resolução de conflitos por razão da dissolução de um casamento, preservando o patrimônio e o interesse dos filhos, dentro do que existe em lei.');

    handleOpenModal('familia');
  }
  const openModalPatrimonial = () => {
    setTitleModal('Assessoria e consultoria completa para planejar, com segurança, economia e tranquilidade a sucessão patrimonial.');

    handleOpenModal('patrimonial');
  }
  const openModalImaterial = () => {
    setTitleModal('Proteção das marcas, patentes e toda a propriedade intelectual ligada à autoria criativa das pessoas e das empresas.');

    handleOpenModal('imaterial');
  }

  const handleOpenModal = (key) => {
    setOpenModals((pv) => ({...pv, [key]: true}));
  }

  const onClose = (key) => {
    setOpenModals((pv) => ({...pv, [key]: false}));
  };

  return (
    <>
      <section className="areas" id="areas-atuacao">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-title animate__animated animate__fadeInUp animate__delay-1s">
              <h1 className="title">Áreas de atuação</h1>
              <div className="small-border animate__animated animate__fadeInUp animate__delay-1s"></div>
              <p className="subtitle animate__animated animate__fadeInUp animate__delay-1s">
                Encontre todas as soluções <br /> para seus problemas!
              </p>
            </div>
            <div className="col-12 col-sm-12 col-md-8">
              <div className="row">
                <div className="col-6 col-sm-6 col-md-4 col-item animate__animated animate__fadeInRight animate__delay-1s">
                  <div className="area-item" onClick={openModalRisco}>
                    <div className="area-icon">
                      <img src={Logo} alt="" />
                    </div>
                    <div className="area-name">
                      Redução de risco
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-item animate__animated animate__fadeInRight animate__delay-1s">
                  <div className="area-item" onClick={openModalCriminal}>
                    <div className="area-icon">
                      <img src={Logo} alt="" />
                    </div>
                    <div className="area-name">
                      Direito Criminal
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-item animate__animated animate__fadeInRight animate__delay-1s">
                  <div className="area-item" onClick={openModalTributario}>
                    <div className="area-icon">
                      <img src={Logo} alt="" />
                    </div>
                    <div className="area-name">
                      Direito Tributário
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-item animate__animated animate__fadeInRight animate__delay-1s">
                  <div className="area-item" onClick={openModalSocietario}>
                    <div className="area-icon">
                      <img src={Logo} alt="" />
                    </div>
                    <div className="area-name">
                      Direito Societário
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-item animate__animated animate__fadeInRight animate__delay-1s">
                  <div className="area-item" onClick={openModalTrabalhista}>
                    <div className="area-icon">
                      <img src={Logo} alt="" />
                    </div>
                    <div className="area-name">
                      Direito Trabalhista
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-item animate__animated animate__fadeInRight animate__delay-1s">
                  <div className="area-item" onClick={openModalFamilia}>
                    <div className="area-icon">
                      <img src={Logo} alt="" />
                    </div>
                    <div className="area-name">
                      Direito de Família
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-item animate__animated animate__fadeInRight animate__delay-1s">
                  <div className="area-item" onClick={openModalPatrimonial}>
                    <div className="area-icon">
                      <img src={Logo} alt="" />
                    </div>
                    <div className="area-name">
                      Sucessão Patrimonial
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-4 col-item animate__animated animate__fadeInRight animate__delay-1s">
                  <div className="area-item" onClick={openModalImaterial}>
                    <div className="area-icon">
                      <img src={Logo} alt="" />
                    </div>
                    <div className="area-name">
                      Propriedade Imaterial
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BasicModal 
        isOpen={openModals.risco} 
        onClose={onClose}
        id={'risco'}
        title={titleModal}
        inContact={true}
      >
        <div>
          <p>
            Nossa equipe é habilitada para identificar e lidar com todas as questões jurídicas relativas à integridade corporativa.<br /><br />
          
            Prestamos serviços de consultoria sobre compliance, avaliação de riscos, governança corporativa, gestão de crises e questões correlatas, com experiência em:
          </p>
          <ul>
            <li>
              Realização de due diligence em fusões e aquisições, joint ventures e em relação a terceiros (fornecedores, prestadores de serviço e representantes);
            </li>
            <li>
              Implementação ou revisão de programas de gestão de risco e de compliance.
            </li>
          </ul>

        </div>
      </ BasicModal>

      <BasicModal 
        isOpen={openModals.criminal} 
        onClose={onClose}
        id={'criminal'}
        title={titleModal}
        inContact={true}
      >
        <div>
          <p>
            Temos uma equipe habilitada para assessorar os clientes em todas as áreas relacionadas ao Direito Penal Empresarial, além de identificar e lidar com todas as questões jurídicas relativas à integridade corporativa. <br /><br/>

            Nossa prática abrange uma ampla variedade de serviços, incluindo:
          </p>
          <ul>
            <li>
              Defesa criminal na fase policial e perante todas as instâncias judiciais;
            </li>
            <li>
              Ajuizamento de ações penais privadas;
            </li>
            <li>
              Acompanhamento de procedimentos de busca e apreensão;
            </li>
            <li>
              Treinamento de empregados acerca da conduta a ser adotada perante autoridades policiais; 
            </li>
            <li>
              Investigações em corrupção, lavagem de dinheiro e fraudes à licitação, etc.
            </li>
          </ul>

        </div>
      </ BasicModal>
      
      <BasicModal 
        isOpen={openModals.imaterial} 
        onClose={onClose}
        id={'imaterial'}
        title={titleModal}
        inContact={true}
      >
        <div>
          <p>
            Um trabalho atento de confirmação de autoria e de proteção das marcas, patentes e toda a sorte de direitos autorais é realizado pela nossa equipe de especialistas, para resguardar seu patrimônio imaterial.<br/> <br/>

            Novas questões surgem a todo tempo através das novas tecnologias, por isso, esse tipo de assessoria é fundamental para empresas que possuem propriedade intelectual a preservar.<br/> <br/>

            Desde o registro até o combate ao uso indevido das marcas ou crime de concorrência desleal, atuamos com total eficiência na proteção dos seus interesses. 

          </p>
        </div>
      </ BasicModal>

      <BasicModal 
        isOpen={openModals.tributario} 
        onClose={onClose}
        id={'tributario'}
        title={titleModal}
        inContact={true}
      >
        <div>
          <p>
            Oferecemos serviços de consultoria fiscal e planejamento tributário especializado. Assessoramos na definição e quantificação de riscos e exposição fiscal; realização de planejamento tributário e adesão de regimes de parcelamentos incentivados de tributos.<br/><br/>

            Em litígios de natureza administrativa e judicial, possuímos expertise nas seguintes atividades:
          </p>
          <ul>
            <li>
              Elaboração de impugnações administrativas decorrentes de autos de infração e manifestações de inconformidade, em face de compensações fiscais não homologadas;
            </li>
            <li>
              Preparo de defesas e recursos judiciais em execuções fiscais e medidas cautelares fiscais;
            </li>
            <li>
              Ingresso com medidas judiciais visando à redução de carga tributária e ressarcimento de tributos pagos a maior;
            </li>
            <li>
              Elaboração de mandados de segurança para coibir atos abusivos e ilegais praticados pelas autoridades fazendárias; 
            </li>
            <li>
              Acompanhamento de fiscalizações;
            </li>
            <li>
              Gestão eficiente de passivos fiscais materializados.
            </li>
          </ul>

        </div>
      </ BasicModal>

      <BasicModal 
        isOpen={openModals.societario} 
        onClose={onClose}
        id={'societario'}
        title={titleModal}
        inContact={true}
      >
        <div>
          <p>
            Uma boa relação societária começa com a elaboração de um contrato social eficiente, feito por um profissional especializado.<br/><br/>

            Todas as questões pertinentes à vida da sociedade devem estar contempladas, dos direitos dos sócios minoritários às hipóteses de exclusão de um sócio.<br/><br/>

            Questões de direito societário tem grande influência em reflexos em outras obrigações como trabalhistas e tributárias, por exemplo.<br/><br/>

            Os processos judiciais societários costumam ser longos e o advogado deve estar pronto para bem aconselhar o cliente sobre as opções. Até mesmo para eventuais composições.
          </p>
        </div>
      </ BasicModal>

      <BasicModal 
        isOpen={openModals.trabalhista} 
        onClose={onClose}
        id={'trabalhista'}
        title={titleModal}
        inContact={true}
      >
        <div>
          <p>
            Um bom gerenciamento das rotinas e um trabalho bem feito no momento da despedida, podem evitar muitos transtornos.<br/><br/>

            As obrigações trabalhistas podem se ramificar para os sócios e para outras empresas do grupo e duram mesmo depois da saída da sociedade.<br/><br/>

            A guarda completa organizada dos documentos dos trabalhadores é muito importante e seu advogado pode lhe auxiliar.<br/><br/>

            Em razão do enorme dinamismo dos processos trabalhistas, é fundamental a presença de um advogado que tenha experiência para analisar e propor estratégias de defesa e de ataque.
          </p>
        </div>
      </ BasicModal>

      <BasicModal 
        isOpen={openModals.familia} 
        onClose={onClose}
        id={'familia'}
        title={titleModal}
        inContact={true}
      >
        <div>
          <p>
            As novas organizações familiares têm trazido novos desafios para o Direito e resolver os conflitos quando ocorre a dissolução de um laço matrimonial é nosso principal objetivo. <br/><br/>

            As disputas em direito de família que envolvem, por exemplo, guarda, visitas e pensão para menores, devem ser acompanhadas por profissional que tenha experiência neste ramo do direito que envolve muitas questões além das legais, como questões médicas, psicológicas, sociais, etc.<br/><br/>

            Nossa atuação visa a resolução definitiva dos conflitos de modo a preservar o patrimônio e o interesse de menores. 
          </p>
        </div>
      </ BasicModal>

      <BasicModal 
        isOpen={openModals.patrimonial} 
        onClose={onClose}
        id={'patrimonial'}
        title={titleModal}
        inContact={true}
      >
        <div>
          <p>
            Existem várias alternativas à disposição das pessoas para planejar como gostariam que seus bens fossem partilhados em caso de morte.<br/><br/>

            Falar disso nem sempre é fácil, mas é uma questão necessária.<br/><br/>

            Nossa atuação vai desde o aconselhamento técnico até a preparação de instrumentos de sucessão que podem trazer tranquilidade.

          </p>
        </div>
      </ BasicModal>
    </>
  );
};

export default Areas;
