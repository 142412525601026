import React from "react";
import "./styles.scss";

const AboutUs = () => {
  return (
    <section className="about-us" id="quem-somos">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4">
            <div className="about-title-wrapper">
              <p className="about-section">Quem somos</p>
              <h1 className="title about-title">Almeida Neto e Campanati.</h1>
              <div className="small-border"></div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-8">
            <p className="about-text">
              Amigos de infância, colegas de faculdade e sócios que compartilham
              a mesma paixão pela advocacia e obsessão em resolver o seu
              problema.
              <br />
              <br />O seu relacionamento não é com um escritório de advocacia,
              mas com as pessoas que fazem deste escritório, um meio de se
              dedicar, incansavelmente, a preservar a saúde do seu negócio e a
              sua tranquilidade.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
