export const formatData = () => {
  const date = new Date();
  const formatter = Intl.DateTimeFormat('pt-BR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
  
  return formatter.format(date);
}