import React from "react";
import "./styles.scss";

import LogoHeader from "../../assets/images/logo_branca_horizontal.png";

const Header = () => {
  return (
    <nav className="navbar navbar-expand-lg fixed-top">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img
            src={LogoHeader}
            alt="Logo escritório Almeida Neto e Campanati"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon">
            <i className="fa fa-bars"></i>
          </span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className="nav-link" href="/">
                Home
              </a>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a className="nav-link" href="#quem-somos">
                Quem somos
              </a>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a className="nav-link" href="#areas-atuacao">
                Áreas de atuação
              </a>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a className="nav-link" href="#advogados-socios">
                Sócios
              </a>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <a className="nav-link" href="#blog">
                Midia
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
